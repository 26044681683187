<!--
 * @Descripttion: 发票列表
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-11 14:26:00
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-09-19 11:38:50
-->
<template>
    <div class="invoiceList">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">发票列表</div>
        </div>
        <div class="case-wrap">
            <div class="screen-wrap">
                <el-select clearable v-model="state" @change="stateChange" placeholder="请选择审核状态筛选">
                    <el-option
                        v-for="item in stateOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <div class="search-item">
                    <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                    <el-button class="btns" @click="clickSearch" type="primary">搜索</el-button>
                </div>
            </div>
            <div class="table-wrap" v-loading="loading">
                <el-table :data="tableData" border style="width: 100%">
                    <el-table-column align="center" prop="id" label="ID" width="70"></el-table-column>
                    <el-table-column align="center" prop="enterprise_brand.name" label="品牌名称" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="shop.name" label="云店名称" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="company" label="公司名称" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="identification_number" label="纳税人识别号" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="register_phone" label="联系电话" :show-overflow-tooltip="true"></el-table-column>
                    <!-- <el-table-column align="center" prop="registered_address" label="注册地址" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="bank_of_deposit" label="开户行" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="bank_of_deposit_account" label="开户行账号" :show-overflow-tooltip="true"></el-table-column> -->
                    <el-table-column align="center" prop="create_timex" label="申请时间" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="state" label="申请状态">
                        <template slot-scope="scope">
                            <div v-if="scope.row.state == 0">未审核</div>
                            <div v-if="scope.row.state == 1">通过</div>
                            <div v-if="scope.row.state == 2">不通过</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" width="110" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <div class="operation-wrap">
                                <el-tooltip class="item pointer" effect="dark" content="门店详情" placement="top">
                                    <i @click="clickShopInfo(scope.row)" class="iconfont el-icon-s-shop"></i>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="审核" placement="top">
                                    <i v-if="scope.row.state == 0" @click="clickAudit(scope.row)" class="iconfont el-icon-s-check"></i>
                                </el-tooltip>
                                <el-link class="link" v-if="scope.row.state == 1" target="_blank" :href="scope.row.pdf_link" :underline="false">
                                  <el-tooltip class="item" effect="dark" content="查看发票" placement="top">
                                    <i class="iconfont el-icon-document-checked"></i>
                                  </el-tooltip>
                                </el-link>
                                <el-link class="link" v-if="scope.row.state == 2" target="_blank" :href="scope.row.pdf_link" :underline="false">
                                  <el-tooltip class="item" effect="dark" :content="'驳回原因：'+scope.row.rejection_remarks" placement="top">
                                    <i class="iconfontx el-icon-document-delete"></i>
                                  </el-tooltip>
                                </el-link>
                                <!-- el-icon-document-delete -->
                                <!-- <div v-if="scope.row.state == 2">驳回原因：{{scope.row.rejection_remarks}}</div> -->
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="10"
                        layout="total, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 审核 -->
        <el-dialog
            :visible.sync="isShowAudit" 
            :before-close="handleClose"
            custom-class="dialog-wrap"
            :close-on-click-modal="false">
            <div class="title">发票审核</div>
            <div class="content">
                <div class="item">
                    <div class="tit">审核状态</div>
                    <div class="status-wrap">
                        <el-radio v-model="status" label="1">通过</el-radio>
                        <el-radio v-model="status" label="2">不通过</el-radio>
                    </div>
                </div>
                <div class="item" v-if="status == '1'">
                    <div class="tit">上传发票</div>
                    <div class="upload-wrap">
                        <aliyun-upload 
                            :limitSize="100" :limit="1" :isShopTip="false" :isAccept="true" acceptType="pdf"
                            :btnDisplay="invoice.length<1" listType="picture-card" accept=".pdf"
                            v-model="invoice" @fileNamech="fileNamech">
                        </aliyun-upload>
                        <div v-if="invoice.length>0" class="fileList-box">
                            <div @click="delImageList" class="delFile">x</div>
                            <el-link target="_blank" :href="invoice[0]" :underline="false">
                                <div class="link">
                                    <div class="icon">
                                        <i class="iconfont el-icon-tickets"></i>
                                        <div>{{fileName}}</div>
                                    </div>
                                    <div>点击查看</div>
                                </div>
                            </el-link>
                        </div>
                    </div>
                </div>
                <div class="item" v-if="status == '2'">
                    <div class="tit">不通过原因</div>
                    <div class="status-wrap">
                        <el-input type="textarea" v-model="reject" placeholder="请填写不通过原因"></el-input>
                    </div>
                </div>
                <div class="btn item">
                    <el-button @click="determineAudit" type="primary">确定</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
import AliyunUpload from "@/components/upload/AliyunUpload.vue";
export default {
    components:{
        AliyunUpload,
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'invoiceList',
            state: '',
            stateOptions:[{
                value: 0,
                label: '未审核'
            },{
                value: 1,
                label: '通过'
            },{
                value: 2,
                label: '不通过'
            }],
            loading: false,
            tableData: [],
            currentPage: 1,
            total: 0,
            keyword: '',
            isShowAudit: false,
            status: '1',
            reject: '',
            invoice: [],
            fileName: '',
            auditId: ''
        }
    },
    mounted () {
        this.getInvoiceList();
    },
    methods: {
        fileNamech(item){
            this.fileName = item;
        },
        // 跳转门店详情
        clickShopInfo(row){
            localStorage.removeItem('shop')
            let newPage = this.$router.resolve({
                path: 'shopDataInfo',
            });
            window.open(newPage.href, '_blank');
            localStorage.setItem('shop',JSON.stringify(row.shop));
            localStorage.setItem('jump',6);
        },
        // 审核
        determineAudit(){
            if(this.invoice.length<1 && this.status == '1'){
                this.$message.error('请上传发票');
                return;
            }
            if(this.reject == '' && this.status == '2'){
                this.$message.error('请填写不通过原因');
                return;
            }
            if(this.status == '1'){
                this.reject = ''
            }
            if(this.status == '2'){
                this.invoice = []
            }
            let params = {
                id: this.auditId,
                state: this.status,
                rejection_remarks: this.reject,
                pdf_link: this.invoice.length>0?this.invoice[0]:''
            }
            common.connect('/customerservicev1/invoice/examine',params,(res)=>{
                this.getInvoiceList();
                this.isShowAudit = false;
            })
        },
        // 审核
        clickAudit(row){
            this.isShowAudit = true;
            this.auditId = row.id;
        },
        // 删除上传的pdf
        delImageList(){
            this.invoice.splice(0, 1)
        },
        // 获取数据
        getInvoiceList(){
            let params = {
                page: this.currentPage,
                keyword: this.keyword,
                state: this.state
            }
            this.loading = true;
            common.connect('/customerservicev1/invoice/index',params,(res)=>{
                this.total = res.data.count;
                this.tableData = res.data.list;
                this.loading = false;
            })
        },
        // 搜索
        clickSearch(){
            this.currentPage = 1;
            this.getInvoiceList();
        },
        // 状态选择
        stateChange(){
            this.currentPage = 1;
            this.getInvoiceList();
        },
        handleCurrentChange(val){
            this.currentPage = val;
            this.getInvoiceList();
        },
        handleClose(){
            this.isShowAudit = false;
        },
    },
}
</script>

<style lang='scss'>
.pointer{
  cursor: pointer;
}
.invoiceList{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
        width: 4px;
        height: 20px;
        background: #409EFF;
        border-radius: 10px;
        }
        .title{
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
        .screen-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 20px;
            .search-item{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-left: 20px;
                .el-input{
                    width: 220px;
                    margin-right: 10px;
                }
            }
        }
        .table-wrap{
            margin-top: 20px;
            .image{
                width: 40px;
                cursor: pointer;
            }
            .operation-wrap{
                .iconfont{
                    font-size: 17px;
                    padding: 0 10px;
                    cursor: pointer;
                }
                .iconfontx{
                    font-size: 17px;
                    color: red;
                    padding: 0 10px;
                    cursor: pointer;
                }
                .link{
                    cursor: pointer;
                    color: #409EFF;
                }
            }
            .pagination-wrap{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 20px;
            }
        }
    }
    .dialog-wrap{
        width: 540px;
        .title{
            text-align: center;
            font-size: 18px;
            color: #000;
        }
        .content{
            margin-left: 20px;
            .el-input{
                width: 500px;
            }
            .el-textarea{
                width: 500px;
            }
            .item{
                padding-bottom: 20px;
                .tit{
                    padding-bottom: 20px;
                    color: #000;
                }
                .el-button{
                    width: 500px;
                }
                .upload-wrap{
                    .fileList-box{
                        position: relative;
                        width: 250px;
                        border: 1px solid #eee;
                        border-radius: 6px;
                        padding: 20px 20px;
                        .delFile{
                            position: absolute;
                            top: 5px;
                            right: 5px;
                            background: rgba(0, 0, 0, 0.2);
                            padding: 0 5px;
                            border-radius: 10px;
                            color: #fff;
                            cursor: pointer;
                            
                        }
                        .link{
                            width: 230px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .icon{
                                display: flex;
                                flex-wrap: wrap;
                                align-items: baseline;
                                .iconfont{
                                    font-size: 14px;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .btn-wrap{
            display: flex;
            justify-content: center;
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
}
</style>
